export default {
  consomation1parjour: 'CONSUMPTION 1 PER DAY (G)',
  consomation2parjour: 'CONSUMPTION 2 PER DAY (G)',
  totalnumberofvisitsperday: 'TOTAL NUMBER OF VISITS PER DAY',
  numberofcows: 'NUMBER OF COWS',

  cowTableTitle: 'Cows',
  cowRFIDColumnTitle: 'RFID',
  cowNameColumnTitle: 'Cow',
  cowDietColumnTitle: 'Diet',
  cowConsumption1ColumnTitle: 'Consumption1',
  cowConsumption2ColumnTitle: 'Consumption2',
  toolbarSearchPlaceholder: 'Search',
  bodyEmptyDataSourceMessage: 'No cow',

  chartDoughnutType: 'Doughnut',
  chartBarType: 'Bar',
  chartBarVerticalType: 'Bar vertical',

};
