export default {
  cowTableTitle: 'Liste des vaches',
  tooltipButtonAddNewCow: 'Ajouter une nouvelle vache',
  tooltipShowNoteCow: 'Afficher les notes',
  tooltipDetailPanelTableCow: 'montrer plus d\'informations sur cette vache',
  cowRFID: 'Num. collier',
  cowReference: 'Référence',
  cowName: 'Nom',
  cowBirthDay: 'Date de naissance',
  cowWeight: 'Poids',
  cowType: 'Type',
  cowGroups: 'Groupes',
  cowIdDiet: 'Régime',
  cowIdMother: 'Mère',
  cowIdFather: 'Père',
  cowLastFeedTs: 'Dernière alimentation',
  cowPortion1: 'Portion1',
  cowLastPrFeed1: 'Dernière dose aliment 1',
  cowTotalPerRemain1: 'totalPrRemain1',
  cowTotalFeedPerDay1: 'Aliment 1 consommé',
  cowTotalFeed1: 'Total aliment 1',
  cowPortion2: 'Portion2',
  cowLastPrFeed2: 'Dernière dose aliment 2',
  cowTotalPerRemain2: 'totalPrRemain2',
  cowTotalFeedPerDay2: 'Aliment 2 consommé',
  cowTotalFeed2: 'Total aliment 2',
  cowNote: 'Note',
  cowUpdatedAt: 'Mise à jour', // date of the last cow info update
  titleStep1: 'Général',
  titleStep2: 'Détail',
  infoStep2: 'Compléter les info de vache',
  selectCowFather: 'Sélectionner le père de vache',
  selectCowMother: 'Sélectionner la mère de vache',
  selectCowState: 'Sélectionner l\'etat de vache',
  selectCowDiet: 'Sélectionner le regime de vache',
  addCowToGroup: 'Ajouter la vache au groupes',


  // message
  checkWeightMessageUpdateCow: 'Vérifier le poids',
  checkBirthdayMessageUpdateCow: 'Vérifier le date de naissance',
  checkReferenceMessageUpdateCow: 'Vérifier la référence',
  checkRFIDMessageUpdateCow: 'Vérifier le numéro',
  checkNameMessageUpdateCow: 'Vérifier le nom',

  msgDietSuccessfullyAdded: 'Vache ajoutée avec succès',
  msgDietCantBeAdded: 'La vache ne peut pas être ajoutée',

  msgCowSuccessfullyUpdated: 'Vache mise à jour avec succès',
  msgCowCantBeUpdated: 'La vache ne peut pas être mise à jour',

  msgCowSuccessfullyRemoves: 'Vache supprimée avec succès',
  msgCowCantBeRemoves: 'La vache ne peut pas être supprimée',
};
