export default {
  tableTitle: 'Liste des régimes',
  dietName: 'Nom',
  startTime: 'Début',
  breakTime: 'Fin',
  minInterval: 'Intervalle',
  // fodder 1
  foodName1: 'Aliment 1',
  quantityPerDay1: 'Quantité aliment 1 / jour',
  maxPerPortion1: 'Dose maximale aliment 1',
  maxPerDay1: 'Max aliment 1 / jour',

  //  fodder 2
  foodName2: 'Aliment 2',
  quantityPerDay2: 'Quantité aliment 2 / jour',
  maxPerPortion2: 'Dose maximale aliment 2',
  maxPerDay2: 'Max aliment 2 / jour',

  // Name field entry control messages
  msgErrorPositiveValue: 'Valeur positive',
  msgErrorFieldEmpty: 'Est requis',
  msgErrorFieldMaxLength: 'Taille max 20',
  msgErrorFieldisExist: 'Déjà utilisé',
  msgErrorFieldStartFeedTime: 'Debut < Fin',
  msgErrorFieldEndFeedTime: 'Debut < Fin',
  msgErrorFieldInterFeedMinIT: 'Min 15 Mn',
  msgErrorFieldMaxPerPortion1: 'inférieure à maximum d\'aliment',
  msgErrorFieldDayQuantity1: 'La quantité d\'aliment 1 par jour doit être positive et inférieure à maximum d\'aliment',
  msgErrorFieldMaxPerPortion2: 'La dose maximale d\'aliment 2 par jour doit être positive et inférieure à maximum d\'aliment',
  msgErrorFieldDayQuantity2: '>= max aliment',
  msgInfoNotUpdated: 'Aucune information n\'est changée',
  // foodName2: 'Nom d\'aliment 2',
  // maxPerDay2: 'Max / jour 2',
  // maxPerPortion2: 'Max / portion 2',
  // quantityPerDay2: 'Quantity / jour 2',
  // message api
  msgDietSuccessfullyAdded: 'Régime ajoutée avec succès',
  msgDietCantBeAdded: 'Le régime ne peut pas être ajouté',

  msgDietSuccessfullyRemoves: 'Régime supprimée avec succès',
  msgDietCantBeRemoves: 'Le régime ne peut pas être supprimé',

  msgDietSuccessfullyUpdated: 'Régime mise à jour avec succès',
  msgDietCantBeUpdated: 'Le régime ne peut pas être mis à jour',

};
