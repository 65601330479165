import menu from './menu';
import dietPage from './dietPage';
import fodderPage from './fodderPage';
import cowPage from './cowPage';
import profilePage from './profilePage';
import StatisticsPage from './StatisticPage';
import homePage from './homePage';
import localizationTable from './localizationTable';
import notif from './notif';

export default {
  ...menu,
  ...dietPage,
  ...fodderPage,
  ...cowPage,
  ...profilePage,
  ...StatisticsPage,
  ...homePage,
  ...localizationTable,
  ...notif,
};
