export default {
  fodderTableTitle: 'Liste des aliments',
  idFodder: 'Numéro',
  fodderName: 'Nom',
  density: 'Poids de référence',
  dateAdded: 'Date de création',
  // message api
  msgFodderSuccessfullyAdded: 'Aliment ajouté avec succès',
  msgFodderCantBeAdded: 'L\'aliment ne peut pas être ajouté',

  msgFodderSuccessfullyRemoves: 'Aliment supprimé avec succès',
  msgFodderCantBeRemoves: 'L\'aliment ne peut pas être supprimé',

  msgFodderSuccessfullyUpdated: 'Aliment mis à jour avec succès',
  msgFodderCantBeUpdated: 'L\'aliment ne peut pas être mis à jour',
};
