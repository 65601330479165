export default {
  logout: 'Logout',
  home: 'Home',
  cow: 'Cows',
  group: 'Groups',
  diet: 'Diets',
  food: 'Food',
  statistics: 'Statistics',
  wifeeder: 'Stations',
};
