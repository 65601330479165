export default {
  btn1HourLabel: '1 hour',
  btn4HoursLabel: '4 hours',
  btn12HoursLabel: '12 hours',
  btn1DayLabel: '1 day',
  btn3DaysLabel: '3 days',
  btn7DaysLabel: '7 days',
  btn15DaysLabel: '15 days',
  btn1MonthLabel: '1 month',
  btn4MonthsLabel: '4 months',
  btnAllLabel: 'All',
};
