export default {
  cowTableTitle: 'Cows list',
  tooltipButtonAddNewCow: 'Add new cow',
  tooltipShowNoteCow: 'Show notes',
  tooltipDetailPanelTableCow: 'Show more information about this cow',
  cowRFID: 'RFID',
  cowReference: 'Reference',
  cowName: 'Name',
  cowBirthDay: 'BirthDay',
  cowWeight: 'Weight',
  cowType: 'Type',
  cowGroups: 'groups',
  cowIdDiet: 'Diet',
  cowIdMother: 'Mother',
  cowIdFather: 'Father',
  cowLastFeedTs: 'Last feed time',
  cowTotalPerRemain1: 'Total remain / Portion 1',
  cowLastPrFeed1: 'Last portion 1',
  cowTotalFeedPerDay1: 'Total / day 1',
  cowTotalFeed1: 'Total feed 1',
  cowTotalPerRemain2: 'Total remain / Portion 2',
  cowLastPrFeed2: 'Last per feed 2',
  cowTotalFeedPerDay2: 'Total / day 2',
  cowTotalFeed2: 'Total feed 2',
  cowPortion1: 'Portion 1',
  cowPortion2: 'Portion 2',
  cowNote: 'note',
  cowUpdatedAt: 'Updated At', // date of the last cow info update

  titleStep1: 'General',
  titleStep2: 'Detail',
  infoStep2: 'Complete cow info',
  selectCowFather: 'Select the cow father',
  selectCowMother: 'Select the cow mother',
  selectCowState: 'Select cow state',
  selectCowDiet: 'Select cow diet',
  addCowToGroup: 'add cow to groups',



  //message
  checkWeightMessageUpdateCow: 'Check weight',
  checkBirthdayMessageUpdateCow: 'Check birthday',
  checkReferenceMessageUpdateCow: 'Check reference',
  checkRFIDMessageUpdateCow: 'Check RFID',
  checkNameMessageUpdateCow: 'Check Name',

  msgCowSuccessfullyAdded: 'the cow successfully added',
  msgCowCantBeAdded: 'the cow can\'t be added',

  msgCowSuccessfullyUpdated: 'the cow successfully updated',
  msgCowCantBeUpdated: 'the cow can\'t be updated',

  msgCowSuccessfullyRemoves: 'the cow successfully removes',
  msgCowCantBeRemoves: 'the cow can\'t be removes',

};
