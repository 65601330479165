export default {
  btn1HourLabel: '1 heure',
  btn4HoursLabel: '4 heures',
  btn12HoursLabel: '12 heures',
  btn1DayLabel: '1 jour',
  btn3DaysLabel: '3 jours',
  btn7DaysLabel: '7 jours',
  btn15DaysLabel: '15 jours',
  btn1MonthLabel: '1 mois',
  btn3MonthsLabel: '3 mois',
  btn4MonthsLabel: '4 mois',
  btnAllLabel: 'Tout',

};
