export default {
  bodyEmptyDataSourceMessageCowTable: 'Cows not found',
  bodyEmptyDataSourceMessageDietTable: 'No diet to display',
  bodyAddTooltip: 'Add',
  bodyDeleteTooltip: 'Delete',
  bodyEditTooltip: 'Edit',
  bodyFilterRowTooltip: 'Filter',

  editRowDeleteText: 'Are you sure delete this row?',
  editRowCancelTooltip: 'Cancel',
  editRowSaveTooltip: 'Save',

  groupingPlaceholder: 'Drag headers ...',
  groupingGroupedBy: 'Grouped By:',

  headerActions: 'Actions',

  paginationLabelDisplayedRows: '{from}-{to} of {count}',
  paginationLabelRowsSelect: 'rows',
  paginationLabelRowsPerPage: 'Rows per page:',
  paginationFirstAriaLabel: 'First Page',
  paginationFirstTooltip: 'First Page',
  paginationPreviousAriaLabel: 'Previous Page',
  paginationPreviousTooltip: 'Previous Page',
  paginationNextAriaLabel: 'Next Page',
  paginationNextTooltip: 'Next Page',
  paginationLastAriaLabel: 'Last Page',
  paginationLastTooltip: 'Last Page',

  toolbarAddRemoveColumns: 'Add or remove columns',
  toolbarNRowsSelected: '{0} row(s) selected',
  toolbarShowColumnsTitle: 'Afficher les colonnes',
  toolbarShowColumnsAriaLabel: 'Afficher les colonnes',
  toolbarExportTitle: 'Exportation',
  toolbarExportAriaLabel: 'Exportation',
  toolbarExportName: 'Exporter au format CSV',
  toolbarSearchTooltip: 'Chercher',
  toolbarSearchPlaceholder: 'Chercher',
};
