export default {
  consomation1parjour: 'ALIMENT 1 CONSOMME / JOUR (g) ',
  consomation2parjour: 'ALIMENT 2 CONSOMME / JOUR (g)',
  totalnumberofvisitsperday: 'TOTAL DES VISITES / JOUR',
  numberofcows: 'NOMBRE TOTAL DE VACHES',

  cowTableTitle: 'Activité des vaches',
  cowRFIDColumnTitle: 'Numéro',
  cowNameColumnTitle: 'Nom',
  cowDietColumnTitle: 'Régime',
  cowConsumption1ColumnTitle: 'Aliment 1 consommé',
  cowConsumption2ColumnTitle: 'Aliment 2 consommé',
  toolbarSearchPlaceholder: 'Chercher',
  bodyEmptyDataSourceMessage: 'Aucune activité',

  chartDoughnutType: 'Donut',
  chartBarType: 'Bandes horizontales',
  chartBarVerticalType: 'Bandes verticales',

};
