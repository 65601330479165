export default {
  editProfile: 'Editer le profile ',
  completeYourProfile: '',
  changePassword: 'Changer le mot de passe',
  oldPassword: 'Ancien mot de passe',
  newPassword: 'Nouveau mot de passe',
  confirmPassword: 'Confirmer le nouveau mot de passe',
  firstName: 'Prénom',
  lastName: 'Nom de famille',
  userName: 'Nom d\'utilisateur',

  passwordHasBeenUpdated: 'Le mot de passe a été mis à jour',
  passwordHasNotBeenUpdated: 'Le mot de passe n\'a pas été mis à jour',


};
