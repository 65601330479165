/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
  HashRouter, Route, Switch, Redirect,
} from 'react-router-dom';


// import { renderRoutes } from 'react-router-config';
import './App.scss';


const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./views/Pages/Login'));
const Register = React.lazy(() => import('./views/Pages/Register'));
const Page404 = React.lazy(() => import('./views/Pages/Page404'));
const Page500 = React.lazy(() => import('./views/Pages/Page500'));

/**
 * protect routes, access to route only if Authorized
 */
const isAuthenticated = () => {
  if ('email' in localStorage) {
    return true;
  }
  return false;
};

const UnauthenticatedRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (
      !isAuthenticated()
        ? <Component {...props} />
        : <Redirect to="/" />
    )}
  />
);

const AuthenticatedRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (
      isAuthenticated()
        ? <Component {...props} />
        : <Redirect to="/login" />
    )}
  />
);
function App() {
  // render() {
  return (
    <HashRouter>
      <React.Suspense fallback={loading()}>
        <Switch>
          <UnauthenticatedRoute exact path="/login" name="Login" component={Login} />
          <Route exact path="/register" name="Register" render={(props) => <Register {...props} />} />
          <Route exact path="/404" name="Page 404" render={(props) => <Page404 {...props} />} />
          <Route exact path="/500" name="Page 500" render={(props) => <Page500 {...props} />} />
          <AuthenticatedRoute path="/" name="Home" component={DefaultLayout} />
        </Switch>
      </React.Suspense>
    </HashRouter>
  );
  // }
}

export default App;
