export default {
  editProfile: 'Edit profile - ',
  completeYourProfile: 'Complete your profile',
  changePassword: 'Change password',
  oldPassword: 'Old password',
  newPassword: 'New Password',
  confirmPassword: 'Confirm new password',
  firstName: 'First name',
  lastName: 'Last name',
  userName: 'User name',

  passwordHasBeenUpdated: 'Password has been updated',
  passwordHasNotBeenUpdated: 'password has not been updated',
};
