export default {
  tableTitle: 'Diet list',
  dietName: 'Diet',
  startTime: 'Start time',
  breakTime: 'Break time',
  minInterval: 'Min interval',
  foodName1: 'Food name 1',
  maxPerDay1: 'Max / day 1',
  maxPerPortion1: 'Max / portion 1',
  quantityPerDay1: 'Quantity / day 1',
  foodName2: 'Food name 2',
  maxPerDay2: 'Max / day 2',
  maxPerPortion2: 'Max / portion 2',
  quantityPerDay2: 'Quantity / day 2',

  // Name field entry control messages
  msgErrorFieldEmpty: 'Is required',
  msgErrorFieldMaxLength: 'Max size 20',
  msgErrorFieldisExist: 'Already used',
  msgErrorFieldStartFeedTime: 'The start time must always be less than the end time',
  msgErrorFieldEndFeedTime: 'The start time must always be less than the end time',
  msgErrorFieldInterFeedMinIT: 'Interval must be more than 15 minutes',
  msgErrorFieldMaxPerDay1: 'The maximum of food 1 per day must be positive',
  msgErrorFieldMaxPerPortion1: 'The maximum dose of food 1 per day must be positive and less than the maximum of food',
  msgErrorFieldDayQuantity1: 'The amount of food 1 per day must be positive and less than the maximum of food',
  msgErrorFieldMaxPerDay2: 'The maximum of food 2 per day must be positive',
  msgErrorFieldMaxPerPortion2: 'The maximum dose of food 2 per day must be positive and less than the maximum of food',
  msgErrorFieldDayQuantity2: 'The amount of food 2 per day must be positive and less than the maximum of food',
  msgInfoNotUpdated: 'No information is changed',

  // message api
  msgDietSuccessfullyAdded: 'the diet successfully added',
  msgDietCantBeAdded: 'the diet can\'t be added',

  msgDietSuccessfullyRemoves: 'the diet successfully removes',
  msgDietCantBeRemoves: 'the diet can\'t be removes',

  msgDietSuccessfullyUpdated: 'the diet successfully updated',
  msgDietCantBeUpdated: 'the diet can\'t be updated',

};
