export default {
  logout: 'Se déconnecter',
  home: 'Accueil',
  cow: 'Vaches',
  group: 'Lots',
  diet: 'Régimes',
  food: 'Aliments',
  statistics: 'Statistiques',
  wifeeder: 'Distributeurs',
};
